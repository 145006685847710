* {
  box-sizing: border-box;
  font-family: "Golos Text";
  color: #1E1E1E;
}

body {
  background-color: #F4F6FA;
}

.AppContainer {
  width: 100%;
  height: 100vh;
  padding: 2%;
  padding-top: 0%;
}

.AppHeader {
  width: 100%;
  height: 15%;
  display: flex;
  justify-content: space-between;
  padding-left: 2%;
  padding-right: 2%;
}

.NavContainer {
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.LogoContainer {
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.Navsec {
  width: 30%;
  font-size: 20px;
  color: #1E1E1E;
  display: flex;
  white-space: nowrap;
}

.Navsec img {
  width: 20px;
  height: 20px;
  margin-top: auto;
  margin-bottom: auto;
}

.Navsec button {
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
  transition: 0.3s;
  font-size: 1.1rem;
}

.Navsec button:hover {
  font-weight: bold;
}

.LogoContainer img {
  width: 65%;
}

.LScontainer {
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
}

.Login-btn {
  background-color: transparent;
  cursor: pointer;
  outline: none;
  border: none;
  width: 25%;
  margin-right: 2%;
  color: #1E1E1E;
  font-size: 1.1rem;
}

.SignUp-btn {
  width: 25%;
  background-color: #1E4B94;
  border: none;
  border-radius: 12px;
  padding: 2%;
  color: #AFBDD4;
  transition: 0.3s;
  font-size: 1.1rem;
}

.SignUp-btn:hover {
  color: #1E1E1E;
  background-color: transparent;
  border: 1px solid #1E4B94;
}

.AppBody {
  width: 100%;
  height: 80%;
  margin-top: 1%;
  display: flex;
}

.ControlDIV {
  position: relative;
  width: 30%;
}

.up-Container {
  width: 70%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding-right: 2%;
}

.Mcontent {
  width: 100%;
  height: 100%;
  position: relative;
}

.fileuploaddiv {
  height: 100%;
  width: 92%;
  background: linear-gradient(180deg, #1E4B94 0%, rgba(30, 75, 148, 0) 100%);
  border-radius: 19px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  position: relative;
  margin-left: 8%;
}

.fileuploaddiv:hover {
  transform: scale(1.004);
}

.fileuploaddiv span {
  width: auto;
  display: block;
  text-align: center;
  font-family: Golos Text;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #E9E9E9;
  margin-top: 2%;
}

.uparrow {
  width: 10%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3%;
  height: auto;
  display: flex;
  align-self: center;
}

.OriginalSceneContainer {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
}

.ConvertedSceneContainer {
  width: 50%;
  height: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  right: 0;
  color: #fff;
  z-index: 1;
}

.Scontent {
  width: 95%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  position: relative;
}

.uploadPrompt {
  color: #E9E9E9;
  text-align: center;
  font-family: Golos Text;
  font-size: 30px;
  font-weight: 700;
  line-height: 43px;
  letter-spacing: 0em;
  text-align: left;
}

.TheBar {
  width: 6px;
  height: 100%;
  /* cursor: col-resize; */
  background-color: white;
  position: relative;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.Catcher {
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #fff;
  border: 6px solid #1E4B94;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.Catcher img {
  width: 40%;
}

.righttar {
  transform: rotate(180deg);
}

.SignUp-btn .file-upload-container {
  height: 200px;
  border: 2px solid #06c;
  background-color: #fff;
  color: white;
  border-radius: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: x-large;
  /* Less round corners for modern look */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  /* For a subtle elevated look */
  transition: all 0.5s ease;
  background: linear-gradient(to right, #06c, #fff);
}

.compress-button {
  background-color: #06c;
  color: #fff;
  width: 60% !important;
}

.Scenecon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.Scenecon1 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}


.canvas-container {
  height: 250px;
  border: none;
  background-color: #ffffff;
  color: #06c;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  /* Spacing between elements */
  border-radius: 10px;
}

.compress-button {
  background-color: #06c;
  color: #fff;
  border: none;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.compress-button:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  /* Intense shadow on hover */
}

/* Specific styles for file input */
input[type=file] {
  cursor: pointer;
  /* Hand cursor on hover to indicate it's clickable */
}

.custom-range-slider {
  width: 50% !important;
  height: 50px;
  /* more styling here */
}

.slideContainer {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
}

.slideContainer span {
  width: 60%;
}

.slideContainer p {
  width: 15%;
}


.custom-range-slider::-webkit-slider-thumb {
  background-color: #06c;
}

span.float-left,
span.float-right {
  color: #06c;
  font-weight: bold;
}

/* Add animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Apply fadeIn animation when file input changes */
input[type=file]:active {
  animation: fadeIn 2s;
}

.company-logo {
  width: 30% !important;
  max-width: 80px;
  margin-bottom: 10px;
}

.company-name {
  font-size: 1.5em;
  font-weight: bold;
  color: #06c;
  width: 50% !important;
}

.file-upload-container {
  cursor: pointer;
  transition: all 0.5s ease;
}

.file-upload-container:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
}

.compress-button:active {
  transform: scale(0.98);
}

.slideContainer input[type=range] {
  transition: all 1s ease;
}

.compress-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.logoCon {
  width: auto;
  height: 10vh;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  align-self: center;
  border-radius: 22px;
  background-color: #e6e6e6;
}

.btncon {
  width: auto;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ControlDIV {
  padding-left: 2%;
}

.TitleControl {
  width: 100%;
  font-family: Golos Text;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 43px;
  letter-spacing: 0em;
  text-align: left;
  color: #1E1E1E;
}

.Slider-Container {
  width: 100%;
  height: auto;
  margin-top: 4%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.InformationContainer {
  position: absolute;
  width: 93%;
  height: auto;
  bottom: 0;
}

.infocontent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.rowinf {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
  margin-bottom: 2%;
}

.rowinf.hint {
  margin-top: 5%;
  margin-bottom: 5%;
}

.rowvalue {
  width: auto;
  font-family: Golos Text;
  font-size: 18px;
  font-weight: 400;
  line-height: 58px;
  letter-spacing: 0em;
  text-align: left;
}

.rowvalue.hint {
  font-family: Golos Text;
  font-size: 19px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: #B0B0B0;
  text-decoration: line-through;

}

.rowtitle {
  width: 50%;
  font-family: Golos Text;
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #1E1E1E;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}

.rowtitle.hint {
  font-family: Golos Text;
  font-size: 19px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: #B0B0B0;
  white-space: nowrap;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.action-btn {
  width: 100%;
  height: 40%;
  position: relative;
  bottom: 0;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.action-btn button {
  width: 100%;
  height: auto;
  border-radius: 22px;
  color: #DBDBDB;
  background-color: #1E4B94;
  font-family: Golos Text;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  outline: none;
  border: none;
  padding: 4%;
  margin-top: 2%;
  margin-bottom: 2%;
  border: 2px solid transparent;
  cursor: pointer;
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.action-btn button:hover {
  background-color: #fff;
  color: #1E4B94;
  border: 2px solid #1E4B94;

}

.SliderDIV {
  width: 100%;
  height: auto;
  border: 1px solid #B0B0B0;
  border-radius: 19px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 3%;
  padding-bottom: 3%;
}

/* .CusRange {
  width: 80%;
  border-radius: 0;
  margin-bottom: 5%;
} */

.CusRange {
  -webkit-appearance: none;
  /* this is to remove the default style of the slider */
  width: 80%;
  height: 25px;
  margin-bottom: 5%;
  background: transparent;
  outline: none;
}

/* Remove outline on thumb in Firefox */
.CusRange::-moz-focus-outer {
  border: 0;
}

.CusRange::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #1E4B94;
  cursor: pointer;
  border-radius: 50%;
  margin-top: -10px;

  box-shadow: 0 2px 2px #0002;
}

.CusRange::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  /* background: #B0B0B0; */
  background: linear-gradient(-90deg, #1E4B94 15.38%, rgba(158, 194, 254, 0) 113.4%);
  border-radius: 1.3px;
}

.CusRange::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #1E4B94;
  cursor: pointer;
  border-radius: 50%;
}

.CusRange::-moz-range-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: #B0B0B0;
}

.CusRange::-moz-range-progress {
  background: #1E4B94;
}

/* 
.LevelsContainer {
  width: 100%;
  display: flex;
  margin-bottom: 2%;
}

.LevelsContainer span {
  margin-left: auto;
  margin-right: auto;
  color: #B0B0B0;
} */
.LevelsContainer {
  width: 80%;
  display: flex;
  margin-bottom: 2%;
  justify-content: space-between;
  /* Adjust this line */
}

.LevelsContainer span {
  color: #B0B0B0;
}

.LevelsContainer span:nth-child(1) {
  text-align: left;
  margin-left: 0;
}

.LevelsContainer span:nth-child(2) {
  text-align: center;
  margin: 0 auto;
}

.LevelsContainer span:nth-child(3) {
  text-align: right;
  margin-right: 0;
}


.TitleScene {
  position: absolute;
  top: 2%;
  color: #f2f2f2;
  font-weight: 600;
  line-height: 43px;
  letter-spacing: 0em;
  text-align: left;
  color: #1E1E1E;
}

.LoadingDIV {
  width: 110%;
  height: 100%;
  margin-left: -5%;
  position: absolute;
  background-color: #1E4B94;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 999;
  color: #f2f2f2;
  font-size: 2rem;
  animation: breathing 2s ease-in-out infinite;
  border-radius: 22px;
}

.loader {
  margin: 5%;
  border: 5px solid #f3f3f3;
  /* Light grey border */
  border-top: 5px solid #3498db;
  /* Blue border */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes breathing {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.02);
  }
}

.Compressbtndown {
  position: absolute;
  bottom: 1%;
  color: #f2f2f2;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #f2f2f2;
  outline: none;
  border: none;
  background-color: #06c;
  border-radius: 22px;
  padding: 2%;
}